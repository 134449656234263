/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { useLocation } from "@gatsbyjs/reach-router";
import { useThemeUI } from "theme-ui";
import { getColor } from "@theme-ui/color";

import PropTypes from "lib/propTypes";

let ADOBE_DTM_ID = `29aba3716ccf`;
if (process.env.GATSBY_ENV === `development`)
  ADOBE_DTM_ID = `d8b9d0d4534d-development`;

const script = [];
if (process.env.NODE_ENV !== `development`)
  script.push({
    src: `https://assets.adobedtm.com/96b96059a3b2/1f85aebe601d/launch-${ADOBE_DTM_ID}.min.js`,
    async: true,
  });

function Seo({ description, lang, meta, shareImage, title }) {
  const { file: icon, site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
        file(relativePath: { eq: "favicons/icon.png" }) {
          childImageSharp {
            gatsbyImageData(layout: FIXED, width: 32, height: 32)
          }
        }
      }
    `
  );

  const location = useLocation();
  const { theme } = useThemeUI();

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `theme-color`,
          content: getColor(theme, `whirlpoolYellow`),
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: `${title} | ${defaultTitle}`,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: shareImage,
        },
        {
          property: `og:url`,
          content: `https://www.wowstudios.com${location.pathname}`,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:image`,
          content: shareImage,
        },
        {
          name: `twitter:creator`,
          content: `Whirlpool`,
        },
        {
          name: `twitter:title`,
          content: `${title} | ${defaultTitle}`,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
      link={[
        {
          rel: `icon`,
          type: `image/png`,
          href: icon.childImageSharp.gatsbyImageData.images.fallback.src,
        },
      ]}
      script={script}
    />
  );
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.shape()),
  shareImage: PropTypes.string,
  title: PropTypes.string.isRequired,
};

Seo.defaultProps = {
  description: ``,
  lang: `en`,
  meta: [],
  shareImage: null,
};

export default Seo;
