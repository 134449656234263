const preset = require(`./src/lib/theme`);

require(`dotenv`).config({
  path: `.env.${process.env.NODE_ENV}`,
});

module.exports = {
  siteMetadata: {
    title: `World of Whirlpool Studios`,
    description: `WoW Studios is the next generation of in-house advertising, storytelling and experience`,
    author: `Aisle Rocket`,
  },
  plugins: [
    `gatsby-plugin-react-helmet`,
    {
      resolve: `gatsby-omni-font-loader`,
      options: {
        preconnect: [`https://use.typekit.net`],
        web: [
          {
            name: `nobel`,
            file: `https://use.typekit.net/buu2rsp.css`,
          },
        ],
      },
    },
    // `gatsby-plugin-image`,
    `gatsby-plugin-resolve-src`,
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${__dirname}/src/images`,
      },
    },
    `gatsby-transformer-sharp`,
    `gatsby-plugin-sharp`,
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        background_color: preset.colors.whirlpoolYellow,
        categories: [`business`],
        description: `Whirlpool leads the work for iconic brands like KitchenAid, Whirlpool, Maytag, JennAir, Amana and more and we’re set on innovating the consumer experience to build the future of marketing.`,
        display: `standalone`,
        icon: `src/images/favicons/manifest.png`,
        icon_options: {
          purpose: `any maskable`,
        },
        include_favicon: false,
        lang: `en-US`,
        name: `World of Whirlpool Studios`,
        short_name: `WoW Studios`,
        start_url: `/`,
        theme_color: preset.colors.whirlpoolYellow,
        theme_color_in_head: false,
      },
    },
    {
      resolve: `gatsby-source-storyblok`,
      options: {
        accessToken: process.env.STORYBLOK_ACCESS_TOKEN,
        version:
          process.env.GATSBY_ENV === `development` ? `draft` : `published`,
        localAssets: true,
      },
    },
    // {
    //   resolve: `gatsby-source-apiserver`,
    //   options: {
    //     url: `https://api8.successfactors.com/odata/v2/JobRequisitionPosting?${new URLSearchParams(
    //       {
    //         $expand: `jobRequisition/jobReqLocale`,
    //         $filter: [
    //           `boardId eq '_external'`,
    //           `(postingStatus eq 'Success' or postingStatus eq 'Updated')`,
    //           `jobRequisition/cust_company eq 'Whirlpool Corporation (0300)'`,
    //         ].join(` and `),
    //         $orderby: `postStartDate desc`,
    //         $select: [
    //           `jobPostingId`,
    //           `jobReqId`,
    //           `jobRequisition/jobReqLocale/externalJobDescription`,
    //           `jobRequisition/jobReqLocale/externalTitle`,
    //           `jobRequisition/location`,
    //         ].join(),
    //       }
    //     )}`,
    //     headers: {
    //       Accept: `application/json`,
    //     },
    //     name: `jobPostings`,
    //     entityLevel: `d.results`,
    //     auth: {
    //       username: process.env.SUCCESS_FACTORS_USERNAME,
    //       password: process.env.SUCCESS_FACTORS_PASSWORD,
    //     },
    //     enableDevRefresh: true,
    //     refreshId: `jobPostingId`,
    //   },
    // },
    `gatsby-plugin-loadable-components-ssr`,
    `gatsby-plugin-emotion`,
    {
      resolve: `gatsby-plugin-react-svg`,
      options: {
        rule: {
          include: /icons.*\.svg$/,
        },
      },
    },
    {
      resolve: `gatsby-plugin-theme-ui`,
      options: {
        preset,
      },
    },
    `gatsby-plugin-layout`,
    `gatsby-plugin-offline`,
    `gatsby-plugin-webpack-bundle-analyser-v2`,
  ],
  trailingSlash: `always`,
};
