import * as React from "react";
import { Fragment, useEffect, useState } from "react";
import { graphql } from "gatsby";
import { kebabCase } from "lodash";
import { sbEditable } from "@storyblok/storyblok-editable";

import BackToTop from "components/BackToTop";
import DynamicSection from "components/DynamicSection";
import ExpandingFlex from "components/layout/ExpandingFlex";
import Spacer from "components/layout/Spacer";
import Seo from "components/Seo";
import PropTypes from "lib/propTypes";
import useStoryblok from "lib/storyblok";
import { setTrackingData } from "lib/trackingData";
import useFooterBorder from "lib/useFooterBorder";
import useTransparentHeader from "lib/useTransparentHeader";

function StoryblokEntry({ data, location }) {
  const story = useStoryblok(data.storyblokEntry, location);

  const titleSlug = location.pathname.split(`/`)[2];
  // eslint-disable-next-line no-underscore-dangle
  const defaultExpandedBlock = story.content.body?.find(
    ({ title }) => kebabCase(title?.toLowerCase()) === titleSlug
  )?._uid;

  const [expandedBlock, setExpandedBlock] = useState(defaultExpandedBlock);

  const toggleExpanded = ({ _uid, title }) =>
    setExpandedBlock((prev) => {
      const isExpandedBlock = prev === _uid;

      let path = `/${story.full_slug}/`;
      if (!isExpandedBlock) path += `${kebabCase(title.toLowerCase())}/`;
      window?.history?.pushState({}, ``, path);
      setTrackingData({
        page: story.name,
        subpage: isExpandedBlock ? null : title,
        url: window?.location.href,
      });

      return isExpandedBlock ? null : _uid;
    });

  const [, setFooterBorder] = useFooterBorder();
  useEffect(
    () => setFooterBorder(story.content.footerBorder),
    [setFooterBorder, story.content.footerBorder]
  );

  const [, setTransparentHeader] = useTransparentHeader();
  useEffect(
    () => setTransparentHeader(story.content.transparentHeader),
    [setTransparentHeader, story.content.transparentHeader]
  );

  useEffect(() => {
    setTrackingData({ page: story.name });
  }, [location.href, story.name]);

  if (story.name === `Global`) return null; // TODO: Pass global story to layout for live editing

  if (!story.content.body) return null;

  const components = story.content.body.map(({ _uid, ...blok }) => {
    let props;
    if (blok.component === `CaseStudy`)
      props = {
        isExpanded: expandedBlock === _uid,
        onClick: () => toggleExpanded({ _uid, ...blok }),
      };

    return (
      <Fragment key={_uid}>
        <DynamicSection
          blok={{ _uid, ...blok }}
          pageSlug={story.full_slug}
          pageTitle={story.name}
          {...props}
        />
      </Fragment>
    );
  });

  return (
    <ExpandingFlex {...sbEditable(story.content)}>
      <Seo
        title={story.name}
        description={story.content.description}
        shareImage={story.content.shareImage.filename}
      />
      {!story.content.transparentHeader && (
        <Spacer size={[80, 100]} bg="whirlpoolYellow" />
      )}
      {components}
      {story.content.backToTop && (
        <BackToTop variant={story.content.backToTop} />
      )}
    </ExpandingFlex>
  );
}

StoryblokEntry.propTypes = {
  data: PropTypes.shape({
    storyblokEntry: PropTypes.shape({
      content: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
      name: PropTypes.string,
      backToTop: PropTypes.oneOf([``, `overlay`, `transparent`, `yellow`]),
    }),
  }).isRequired,
  location: PropTypes.shape({
    href: PropTypes.string,
    pathname: PropTypes.string,
  }).isRequired,
};

export default StoryblokEntry;

export const query = graphql`
  query ($id: String!) {
    storyblokEntry(id: { eq: $id }) {
      name
      full_slug
      content
    }
  }
`;
